.about-body {
  font-family: 'Lato', sans-serif;
  min-height: calc(100vh - 80px);
  margin: 0;
  padding: 0;
  background-color:#9cd4f8;
}
.container {
  margin: 0;
  padding: 80px;
  display: flex;
  flex-direction: row; /* Horizontal layout */
  align-items: center; /* Vertically align items */
}
.text-box {
  padding-right: 30px;
  padding-bottom: 30px;
  box-sizing: border-box;
}
.image-box{
  margin-left: 20px;
  max-width: 100%;
}
.container text-box {
  padding-right: 30px;
  padding-bottom: 30px;
  box-sizing: border-box;
}
.container image-box{
  margin-left: 20px;
  max-width: 100%;
}

@media screen and (max-width: 900px) {
  .container {
    margin: 0;
    padding: 80px;
  }
  .image-box{
    margin-left: 0;
    width: 0;
  }
  .text-box {
    padding-right: 0;
    padding-bottom: 30px;
    box-sizing: border-box;
  }
  .container text-box {
    padding-right: 0;
    padding-bottom: 30px;
    box-sizing: border-box;
  }
}
@media screen and (max-width: 550px) {
  .container {
    margin: 0;
    padding: 20px;
  }
}
.clear {
  clear: both;
}
.contact-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #ff1500;
  color: #fff;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  font-weight: bold;
  transition: background-color 0.3s;
}

.contact-button:hover {
  background-color: #ff26009b; /* Change the color on hover */
}
