/* NotFoundPage.css */

/* Styles for the overall container */
.not-found-background-image {
    background: url('../../../public/images/bagel-logo.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    width: 100%;
    height: 100%;
    opacity: 1;
    
  }

.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    background-color: rgba(95, 95, 196, 0.8);
  }
  
  /* Styling for the 404 text */
  .not-found-h1 {
    font-size: 3rem;
    color: rgb(71, 197, 255); /* or any color of your choice */
  }
  
  /* Styling for the description */
  .not-found-p {
    font-size: 1.5rem;
    margin-top: 10px;
    color: #f6f0f0;
    opacity: 0.7;
  }
  
  /* Fun animation for the 404 text */
  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-20px);
    }
    60% {
      transform: translateY(-10px);
    }
  }
  
  .not-found-h1 {
    animation: bounce 2s infinite;
  }
  