body {
    font-family: 'Lato', sans-serif;
    margin: 0;
    padding: 0;
}

header {
    background-color: #333;
    color: #c54343;
    text-align: center;
    padding: 10px;
}

h1 {
    margin: 0;
}

.contact-container {
    margin: 0 auto;
    min-height: calc(100vh - 80px);
    padding: 20px;
    padding-left: 80px;
    padding-right: 80px;
    background-color: #f55c4e;
}

.contact-info {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
    padding-top: 40px;
    padding-left: 120px;
    padding-right: 120px;
}

.contact-item {
    text-align: center;
}

.contact-item img {
    width: 50px;
    height: 50px;
}

.contact-item a {
    color: black;
    text-decoration: none;
}

.map-section{
    margin-top: 20px;
}

.map-section iframe{
    width: 100%;
    border: 1px solid #f6f0f0;
    border-radius: 5px;
}

@media screen and (max-width: 1200px) {
    .contact-info {
        display: flex;
        justify-content: space-around;
        margin-bottom: 20px;
        padding-top: 40px;
        padding-left: 0px;
        padding-right: 0px;
    }
}

@media screen and (max-width: 500px) {
    .contact-container {
        margin: 0 auto;
        min-height: calc(100vh - 80px);
        padding: 20px;
        padding-left: 20px;
        padding-right: 20px;
        background-color: #f55c4e;
    }
    .map-section{
        margin-top: 20px;
        display: flex;
        flex-direction: row; /* Horizontal layout */
        align-items: center; /* Vertically align items */
        margin: 0;
    }
    .contact-info {
        display: flex;
        flex-direction: column; /* vert layout */
        align-items: center; /* Vertically align items */
        margin-bottom: 0px;
        padding-right: 20px;
        padding-top: 0px;
    }
    .contact-item{
        padding-bottom: 40px;
    }
}
@media screen and (max-width: 450px) {
    .contact-container {
        margin: 0 auto;
        min-height: calc(100vh - 80px);
        padding: 20px;
        padding-left: 20px;
        padding-right: 20px;
        background-color: #f55c4e;
    }
    .map-section{
        margin-top: 20px;
        display: flex;
        flex-direction: row; /* Horizontal layout */
        align-items: center; /* Vertically align items */
        margin: 0;
    }
    .contact-info {
        display: flex;
        flex-direction: column; /* vert layout */
        align-items: center; /* Vertically align items */
        margin-bottom: 0px;
        padding-right: 20px;
        padding-top: 0px;
    }
    .contact-item{
        padding-bottom: 40px;
    }
    .map-section iframe{
        width: 75%;
        border: 1px solid #f6f0f0;
        border-radius: 5px;
    }
}
