  
  .main-container {
    background: url('../../public/images/bagel-logo.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: scroll;
    min-height: calc(100vh - 80px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.397);
    object-fit: contain;
    text-align: center;
  }

  .main-container > h1 {
    color: #fff;
    font-size: 70px;
    margin-top: -100px;
  }
  
  .main-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    justify-content: center;
    align-items: center;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .main-btns {
    margin-top: 32px;
  }
  
  .main-btns .btn {
    margin: 6px;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }
  
  @media screen and (max-width: 960px) {
    .main-container > h1 {
      font-size: 70px;
      margin-top: -150px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .main-container > h1 {
      font-size: 50px;
      margin-top: -100px;
    }
  
    .main-container > p {
      font-size: 30px;
      justify-content: center;
      align-items: center;
    }
  }