body {
    font-family: 'Lato', sans-serif;
    background-color: #f9f9f9;
    margin: 0;
    padding: 0;
}

header {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 10px;
}

h1 {
    margin: 0;
}

.menu-container {
    margin: 0 auto;
    min-height: calc(100vh - 80px);
    background-color: #dd9207;
    padding: 20px;
    padding-left: 80px;
    padding-right: 80px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h2 {
    margin-top: 0;
}

.category {
    margin-top: 10px;
}

.subcategory {
    font-weight: bold;
    margin-top: 10px;
}
.title {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}
.item {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
}
.toppings {
    display: flex;
    margin-top: 5px;
}

.box{
    border: 1px solid #e79909;
    padding: 10px;
    border-radius: 5px;
}

/* Add hover effect to items */
.box:hover {
    background-color: #e79909;
    transition: background-color 0.3s;
}

/* Style description */
.box p {
    margin: 0;
    font-style: italic;
}

@media screen and (max-width: 650px) {
    .menu-container {
        margin: 0 auto;
        min-height: calc(100vh - 80px);
        background-color: #dd9207;
        padding: 20px;
        padding-left: 20px;
        padding-right: 20px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
}


